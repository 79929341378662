import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/auth/callback": [26,[3]],
		"/auth/login": [27,[3]],
		"/auth/password-recovery": [28,[3]],
		"/(app)/booking": [~5,[2]],
		"/(app)/building": [~6,[2]],
		"/(app)/building/[id]": [~7,[2]],
		"/(app)/catering": [8,[2]],
		"/(app)/catering/menus": [9,[2]],
		"/(app)/catering/menus/edit": [10,[2]],
		"/(app)/catering/products": [11,[2]],
		"/(app)/catering/products/edit": [12,[2]],
		"/(app)/catering/statistics": [13,[2]],
		"/(app)/digital-twin": [14,[2]],
		"/(app)/home": [~15,[2]],
		"/(app)/home/[id]": [~16,[2]],
		"/(app)/monitoring": [17,[2]],
		"/(app)/my-controls": [~18,[2]],
		"/(app)/my-controls/[id]": [~19,[2]],
		"/(app)/services": [20,[2]],
		"/(app)/settings": [~21,[2]],
		"/(app)/suppliers": [22,[2]],
		"/(app)/tickets": [23,[2]],
		"/(app)/tickets/[id]": [~24,[2]],
		"/(app)/visitors": [25,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';